import { useDisclosure, useToast } from "@chakra-ui/react";
import { useParams } from "@reach/router";
import { navigate } from "gatsby";
import React, { Dispatch, useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { $map } from "foundation-ts/array";
import { $count, $isstring, $length, $ok, $tounsigned, $value } from "foundation-ts/commons";
import { $uuid } from "foundation-ts/crypto";
import { $timeBetweenDates } from "foundation-ts/date";
import { $newext, $withoutext } from "foundation-ts/fs";
import { TSDate, TSDay } from "foundation-ts/tsdate";
import { TSInterval } from "foundation-ts/tsinterval";
import { $bool } from "foundation-ts/tsparser";
import { Nullable, TSDataLike, uint32, uint8, UUID } from "foundation-ts/types";
import { $inspect } from "foundation-ts/utils";
import { DriveItemStructure, SignatureMode } from "g1-commons/lib/doxecureTypes";
import { CloudSubscriptionDto, DocumentDto, SessionDto, SignatureDto } from "g1-commons/lib/doxecureClientTypes";
import { SessionStatus, SessionSystemType, SignaturePhase, SignatureStatus, SignatureType } from "g1-commons/lib/doxecureModelTypes";
import { BCADocumentFile, BCADocumentFileType } from "bca-client-signature/lib/BCADocuments";
import { extractSignaturesFromPdf } from "bca-client-signature/lib/BCASignatureEngine";

import { PageShellWithThreeColumns } from "../../components/PageShellWithThreeColumns/PageShellWithThreeColumns";
import { WaitingModal } from "../../components/Modals/WaitingModal";
import { AsyncConfirmModal, AsyncConfirmModalState, DefaultAsyncConfirmModalState } from "../../components/Modals/AsyncConfirmModal";

import { config } from "../../config/config";
import useCreateOrUpdateDraftSession from "../../hooks/useCreateOrUpdateDraftSession";
import useCreateOrUpdateTemplateSession from "../../hooks/useCreateOrUpdateTemplateSession";
import useSession from "../../hooks/useSession";
import useProfile from "../../hooks/useProfile";
import { DocumentContentID } from "../../hooks/hooksConstants";
import { publishSession } from "../../services/session.service";
import { getUserUuid } from "../../services";
import { g1call } from "../../services/apiClient";
import { downloadDriveFile } from "../../services/drive.service";
import { NeededPersonRole } from "../../utils/TypesAndConstants";
import { ToastInfo, defaultErrorToast, defaultWarningToast, defaultSuccessToast, toastInfo, defaultInfoToast } from "../../utils/toast";
import { blobToURLData, fileNameString, okOrEmptyCard, optlog, trueOrEmptyCard } from "../../utils/functions";

import { DocumentPanel } from "../signature/DocumentPanel";
import { DriveExplorerPanel } from "../signature/DriveExplorerPanel";
import { PersonItem, PersonPanel } from "../signature/PersonPanel";
import { SessionCreationPanel } from "../signature/SessionCreationPanel";
import { TemplateSessionCreationPanel } from "../templateSessions/TemplateSessionCreationPanel";

enum RightPanelType {
    Document,
    Drive,
    PersonSelection
}

interface NewDocumentDto extends DocumentDto {
    conversionWarning?:string ;
}

interface NewSessionViewProps {
    systemType: SessionSystemType ;
}

export const NewSessionView = (props: NewSessionViewProps) => {
    const { systemType } = props ;
    // State value to know which panel we should display on the right pane
    const profileId = getUserUuid() ;
    const params = useParams();
    const { data: currentSession, isError, isLoading } = useSession(params.sessionId, profileId);
    const { mutateAsync: updateOrCreateDraftSession } = useCreateOrUpdateDraftSession(profileId);
    const { mutateAsync: updateOrCreateTemplateSession } = useCreateOrUpdateTemplateSession(profileId);
    const [signatureMode, setSignatureMode] = useState<SignatureMode>(SignatureMode.Visible) ;
    const [optionInvisibleSwitchChecked, setOptionInvisibleSwitchChecked] = useState(false) ;

    // What to display in the right panel
    const [rightPaneContent, setRightPaneContent] = useState<RightPanelType | undefined>(undefined);
    const [document, setDocument] = useState<NewDocumentDto | undefined>(undefined);
    const [pdfSignatures, setPdfSignatures] = useState<SignatureDto[]>([]) ;
    const [drive, setDrive] = useState<CloudSubscriptionDto | undefined>(undefined);
    const [personRole, setPersonRole] = useState<NeededPersonRole | undefined>(undefined);
    const { data: userProfile, isError:profileError, isLoading:profileLoading } = useProfile(profileId, 'NewSessionView');
    const [canAddConnectedUser, setCanAddConnectedUser] = useState(false) ;
    const [signature, setSignature] = useState<SignatureDto | undefined>(undefined) ;
    // Modals
    const [openFailModal, setOpenFailModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(DefaultAsyncConfirmModalState);
    // Other
    const [session, setSession] = useState<SessionDto>();
    const [blacklist, setBlacklist] = useState<Set<UUID>>() ;
    const [modalText, setModalText] = useState('Upload en cours...') ;
    const [uploadedDocumentsSize, setUploadedDocumentsSize] = useState<uint32>(10*1024 as uint32) ;
    const [showToastQualifiedSigner, setShowToastQualifiedSigner] = useState(true) ;
    const [showToastSameAsExpeditor, setShowToastSameAsExpeditor] = useState(true) ;

    const queryClient = useQueryClient();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure() ;

    const acceptsOfficeDocuments =  $bool(process.env.DOXECURE_ACCEPTS_OFFICE_DOCUMENTS) ; 
    optlog(`+++ New ${systemType === SessionSystemType.Template ? "template" : ""} session view initialized with profile ID '${profileId}'${acceptsOfficeDocuments?" (can upload office documents)":""}`) ;

    const clearRightPane = () => {
        setRightPaneContent(undefined);
        setDocument(undefined);
        setPersonRole(undefined);
        setDrive(undefined);
        setBlacklist(undefined);
        setCanAddConnectedUser(false) ;
        setPdfSignatures([]) ;
    };

    // ==================== global actions management ========================================================================================
    const publishSessionForSignature = async () => {
        let saved:boolean = false ;
        setModalText('Mise en signature ...') ;
        onOpen() ;
        try {
            const newSession = await updateOrCreateDraftSession(session!) ;
            if (!$ok(newSession?.apid)) { throw 'Bad save session' ; }
            saved = true ;
            setSession(newSession) ;  
            clearRightPane() ;
  
            if (!await publishSession(newSession!.apid!)) { throw 'Cannot publish' ; }

            // TODO: we could also navigate to 'tobeApprovedDocuments'
            const navigatePoint = newSession?.signatures?.first()?.signer.apid === userProfile!.apid ? 'signatures' : 'tobeSignedDocuments' ;
            toast(defaultSuccessToast("Mise en signature effectuée")) ;
            onClose() ;
            navigate(`/app/${navigatePoint}`, { replace: true });
        } catch (error) {
            toast(defaultErrorToast(saved ? "Impossible de mettre cette session en signature":"Problème rencontré lors de l'enregistrement."));
            onClose() ;
        }
    }

    const saveSessionAsDraft = async () => {
        setModalText('Sauvegarde en cours ...') ;
        onOpen() ;
        try {
            const update = $ok(session?.apid) ;
            const newSession = await updateOrCreateDraftSession(session!);
            if (!$ok(newSession?.apid)) { throw 'Bad save session' ; }
            setSession(newSession) ;    
            clearRightPane() ;
            toast(defaultSuccessToast(update ? "Brouillon sauvegardé" : "Ajouté aux brouillons."));
        } catch (error) {
            toast(defaultErrorToast("Problème rencontré lors de l'enregistrement."));
        }
        onClose() ;
    };

    const saveSessionAsTemplate = async () => {
        setModalText('Sauvegarde en cours ...') ;
        onOpen() ;
        try {
            const update = $ok(session?.apid) ;
            const newSession = await updateOrCreateTemplateSession(session!);
            if (!$ok(newSession?.apid)) { throw 'Bad save session' ; }
            setSession(newSession) ;    
            clearRightPane() ;
            toast(defaultSuccessToast(update ? "Modèle mis à jour" : "Ajouté à la liste de vos modèles."));
        } catch (error) {
            toast(defaultErrorToast("Problème rencontré lors de l'enregistrement."));
        }
        onClose() ;
    };

    // ==================== session card sender and signer management ========================================================================================
    
    const _handleAddPersonAs = (role: NeededPersonRole) => {
        setRightPaneContent(RightPanelType.PersonSelection);
        setPersonRole(role);
        setCanAddConnectedUser(_calculateCanAddMe(userProfile?.apid, session, role)) ;
        setDocument(undefined);
        setPdfSignatures([]) ;
        setDrive(undefined);
        setSignature(undefined) ;
    };

    const handleAddSender = () => { 
        _handleAddPersonAs(NeededPersonRole.Sender);
        setBlacklist(undefined) ; 
    }
    
    const handleAddSigner = () => {
        _handleAddPersonAs(NeededPersonRole.Signer); 
        setBlacklist(session?.signatures?.filteredSet(sign => sign.signer.apid)) ;     
    }

    const handleRemoveSigner = (apid: UUID) => {
        if ($count(session?.signatures) > 0) {
            const newSignatures = session!.signatures!.filter((sign: SignatureDto) => sign.signer.apid !== apid) ;
            const newSession: SessionDto = {
                ...session!,
                signatures: newSignatures,
            };
            setBlacklist(newSignatures.filteredSet(sg=>sg.signer.apid));
            setSession({ ...newSession }) ;    
            if ($ok(signature)) {
                setSignature(undefined) ;
            }
            if ($ok(document)) { 
                // FIXME: s.signatureDate is allways null or undefined here
                const askedSignatures = $map(newSession?.signatures, s => $ok(s.signatureDate) ? null : s) ;
                setPdfSignatures(askedSignatures) ;
                queryClient.invalidateQueries(DocumentContentID);
            }
        }
        else { toast(defaultErrorToast("Pas de signatures à supprimer")) ; }
    };
    
    // ==================== right pane person management ========================================================================================
    const _addNewPersonAsSigner = (personItem:PersonItem, phase:SignaturePhase) => {
        const newSignatures = _signaturesWithNewSigner(userProfile?.apid, session!, personItem, phase, signatureMode) ;
        if (typeof newSignatures === 'string') { toast(defaultErrorToast(newSignatures)) ; }
        else {
            const newSession = {...session!, signatures:newSignatures } ;
            setSession(newSession) ;
            setCanAddConnectedUser(_calculateCanAddMe(userProfile?.apid, newSession, NeededPersonRole.Signer)) ;
            setBlacklist(newSignatures.filteredSet(sg=>sg.signer.apid));
        }
    }

    const _chooseSignerPhase = (personItem: PersonItem) => {
        const message = [
            `Choisissez la qualité ${personItem.isConnectedUser?"de votre signature":"de la signature du signataire"} :`,
            "Soit une signature avancée qui est validée par l'envoi d'un code par courrier électronique,",
            "soit une signature qualifiée validée par un dispositif de sécurité ou une carte dédiée."
        ] ;

        setConfirmModal({
            title: "Ajouter un signataire ?",
            message: message.join(' '),
            cancel: "Annuler",
            confirm: "Qualifiée",
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
                clearRightPane() ;
            },
            onAccept: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
                const qualifiedPhase = session?.signatures?.last()?.phase === SignaturePhase.Server ? SignaturePhase.QSCDEnd : SignaturePhase.QSCDStart ;
                _addNewPersonAsSigner(personItem, qualifiedPhase) ;
                clearRightPane() ;
            },
            actions: [{
                value: undefined,
                label: "Avancée",
                onSelect: async(_selectedValue: any) => {
                    setConfirmModal(DefaultAsyncConfirmModalState) ;
                    _addNewPersonAsSigner(personItem, SignaturePhase.Server) ; 
                    clearRightPane() ;
                }
            }]
        });            
    }

    const _chooseExpeditorPosition = (personItem: PersonItem, message: string[], first: boolean, last: boolean) => {
        const modalState: AsyncConfirmModalState = {
            title: "Changer l'expéditeur ?",
            message: [
                "Vous souhaitez changer l'expéditeur, cependant celui-ci est déjà présent dans la liste des signataires.",
                ...message
            ].join(' '),
            cancel: "Annuler",
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
                clearRightPane() ;
            },
            onAccept: async () => {},
        }

        if (first || last) {
            modalState.confirm = first ? "En première position" : "En dernière position",
            modalState.onAccept = async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
                _changeRankOfOldExpeditor(session!) ;
                const signatures = _reloadSignatures(session!, personItem, first) ;
                const newSession = { ...session!, signatures:signatures, expeditor: personItem.person } ;
                setSession(newSession) ;
                clearRightPane() ;
            } ;
        }

        if (first && last) {
            modalState.size = "xl" ;
            modalState.actions = [{
                value: undefined,
                label: "En dernière position",
                onSelect: async(selectedValue:any) => {
                    setConfirmModal(DefaultAsyncConfirmModalState) ;
                    _changeRankOfOldExpeditor(session!) ;
                    const signatures = _reloadSignatures(session!, personItem, false) ;
                    const newSession = { ...session!, signatures:signatures, expeditor: personItem.person } ;
                    setSession(newSession) ;
                    clearRightPane() ;
                }
            }] ;
        }

        setConfirmModal(modalState) ;            
    }

    const handleSelectPersonAs = async (personItem: PersonItem, role: NeededPersonRole) => {
        if (!$ok(session)) {
            toast(defaultErrorToast("*** SESSION NOT IN PLACE ***")) ;
            return ;
        }

        if (role === NeededPersonRole.Signer) {
            const lastSignature = session?.signatures?.last() ;
            if ((signatureMode === SignatureMode.Invisible) || (lastSignature?.phase === SignaturePhase.QSCDEnd)) {
                if ((signatureMode === SignatureMode.Visible) && showToastQualifiedSigner) { // Show this alert only when creator is in mode "Visible Signature", because, in other mode, we force qualified signature
                    toast(defaultInfoToast("Le signataire précédent a été choisi pour signer en signature qualifiée, les signataires suivants ne peuvent donc être qu'en signature qualifiée également")) ;
                    setShowToastQualifiedSigner(false) ;
                }
                _addNewPersonAsSigner(personItem, ((signatureMode === SignatureMode.Invisible) && $count(session?.signatures) === 0) ? SignaturePhase.QSCDStart : session!.signatures!.last()!.phase) ;
                clearRightPane() ;
            } else if ((lastSignature?.signer.apid === session?.expeditor.apid) && ($count(session?.signatures) > 1)) {
                if (showToastSameAsExpeditor) {
                    toast(defaultInfoToast(`Le dernier signataire étant l'expéditeur, les signataires suivants seront positionnés avant lui en tant que signature ${lastSignature?.signatureType === SignatureType.Connective ? "avancée" : "qualifiée"}`)) ;
                    setShowToastSameAsExpeditor(false) ;
                }
                _addNewPersonAsSigner(personItem, session!.signatures!.last()!.phase) ;
                clearRightPane() ;
            } else { // Server phase or first choice
                _chooseSignerPhase(personItem) ;
            }
        } else if (personItem.person.apid !== session!.expeditor.apid) {
            const indexOfNewExpeditor = session?.signatures?.findIndex(signature => signature.signer.apid === personItem.person.apid) ;
            const nbServerSignatures = session?.signatures?.filter(signature => signature.phase === SignaturePhase.Server).length ;
            const nbQscdStartSignatures = session?.signatures?.filter(signature => signature.phase === SignaturePhase.QSCDStart).length ;

            optlog(`handleSelectPersonAs => ${indexOfNewExpeditor} / ${nbServerSignatures} / ${nbQscdStartSignatures}`) ;

            // If new expeditor is not in signature chain, so we can change it without asking connected user
            // Don't forget to change the rank of old expeditor if he is in signature chain
            if (!$ok(indexOfNewExpeditor) || (indexOfNewExpeditor === -1)) {
                _changeRankOfOldExpeditor(session!) ;
                const newSession = { ...session!, expeditor: personItem.person } ;
                setSession(newSession) ;
                clearRightPane() ;

            // If new expeditor is already in first or last position of signature chain, so only its rank must be changed
            } else if ((indexOfNewExpeditor == 0) || (indexOfNewExpeditor == (session!.signatures!.length - 1))) {
                _changeRankOfOldExpeditor(session!) ;
                const signatures = _reloadSignatures(session!, personItem, indexOfNewExpeditor == 0) ;
                const newSession = { ...session!, signatures: signatures, expeditor: personItem.person } ;
                setSession(newSession) ;
                clearRightPane() ;

            // If all signatures are server OR all signatures are QSCDStart, then it's possible to move expeditor on first or last position
            // Don't forget to change the rank of old expeditor if is in signature chain
            } else if ((nbServerSignatures === session?.signatures?.length) || (nbQscdStartSignatures === session?.signatures?.length)) {
                const message = [
                    "Pour rappel, un expéditeur ne peut être qu'en première ou dernière position de la chaine de signatures.",
                    "A quelle position, voulez-vous le déplacer ?"
                ] ;
                _chooseExpeditorPosition(personItem, message, true, true) ;

            // If new expeditor is in Advanded signature phase, so we ask user to move him at first position, because, currently,
            // Advanced signature phase is before Qualified signature phase
            // Don't forget to change the rank of old expeditor if is in signature chain
            } else if (session?.signatures![indexOfNewExpeditor!].phase === SignaturePhase.Server) {
                const message = [
                    "Cette personne a été choisie pour signer en signature avancée, elle doit donc être placée en première position.",
                    "Voulez-vous la déplacer à cette position ?"
                ] ;
                _chooseExpeditorPosition(personItem, message, true, false) ;

            // If new expeditor is in Qualified signature phase, so we ask user to move him at last position, because, currently,
            // Qualified signature phase is after Advanced signature phase
            // Don't forget to change the rank of old expeditor if is in signature chain
            } else if (session?.signatures![indexOfNewExpeditor!].phase === SignaturePhase.QSCDEnd) {
                const message = [
                    "Cette personne a été choisie pour signer en signature qualifiée, elle doit donc être placée en dernière position.",
                    "Voulez-vous la déplacer à cette position ?"
                ] ;
                _chooseExpeditorPosition(personItem, message, false, true) ;
            }
        } else { toast(defaultWarningToast("Vous avez choisi le même expéditeur")) ; }
    } ;

    // ==================== session card drive selection management ========================================================================================
    const handleSelectDrive = (drive: CloudSubscriptionDto) => {
        setRightPaneContent(RightPanelType.Drive);
        setDrive(drive) ;
        setPersonRole(undefined) ;
        setBlacklist(undefined) ; 
        setCanAddConnectedUser(false) ;
        setPdfSignatures([]) ;
        setDocument(undefined) ;
        setSignature(undefined) ;
    };
    // ==================== right pane drive management ========================================================================================

    const handleSelectFileFromDrive = async (drive: CloudSubscriptionDto, file: DriveItemStructure) => {
        if ($ok(session)) {
            if ($count(session?.documents) >= config.session.maxNbDoc) {
                toast(defaultWarningToast(`Vous avez atteint le nombre maximal de documents pour un dossier de signature.`)) ;
                return ;
            }

            if ($ok(file.size) && (file.size! > config.session.maxDocSize)) {
                toast(defaultErrorToast(`Le fichier ${file.name} dépasse les ${config.session.maxDocSize/(1024*1024)}Mo. Il ne peut pas être sélectionné.`)) ;
                return ;
            }

            if ($ok(file.size) && ((uploadedDocumentsSize + _realDocSize(file.size! as uint32)) > config.session.maxSessionSize)) {
                toast(defaultErrorToast(`La taille maximale du dossier a été dépassée. Vous ne pouvez pas sélectionner le fichier ${file.name}`)) ;
                return ;
            }

            const normalizedFilename = fileNameString(file.name) ;
            optlog(`select file ${file.name} ${normalizedFilename !== file.name ? ('(Normalisé : ' + normalizedFilename + ')') : ''} from drive ${drive.supplier}`) ;
            setModalText('Upload en cours...') ;
            onOpen() ;

            const filteredDoc = session?.documents?.filter(doc => (file.mimeType === 'application/pdf' ? fileNameString(doc.fileName.toLowerCase()) : fileNameString($withoutext(doc.fileName.toLowerCase()))) === normalizedFilename.toLowerCase());
            if ($ok(filteredDoc) && (filteredDoc!.length > 0)) {
                toast(defaultWarningToast(`Un document portant un nom semblable à '${file.name}' a déjà été uploadé`));
            } else {
                const fileData = await downloadDriveFile(getUserUuid(), drive.apid, file.id) ;
                const newDocuments = $count(session?.documents) ? [...session!.documents!] : [] ;
                newDocuments.push({
                    fileName: file.name,
                    fileType:BCADocumentFileType.PDF,
                    mimeType:file.mimeType,
                    size:file.size,
                    pagesCount: 0 as uint32,
                    localUUID: $uuid(),
                    localSource: await blobToURLData(new Blob([fileData], { type: file.mimeType })),
                    lastPageSignRows:3 as uint8,
                } as unknown as NewDocumentDto) ;
                const newSession = {
                    ...session!,
                    documents: newDocuments,
                };
                setSession(newSession) ;
                setUploadedDocumentsSize((uploadedDocumentsSize + _realDocSize(file.size! as uint32)) as uint32) ;
                _handleViewDocument(newDocuments[newDocuments.length-1]) ;
                onClose() ;
            }
        } else {
            toast(defaultErrorToast("Impossible d'uoploader un nouveau document: session courante introuvable.")) ;
        }
    };

    // ==================== session card documents management ========================================================================================
    const handleUploadDocuments = async (files:BCADocumentFile[]|string) => {
        if ($isstring(files)) {
            toast(defaultWarningToast(files as string)) ;
        }
        else if ($ok(session)) {
            const normalizedFilenames = $ok(session!.documents) ? session!.documents!.map(doc => { return fileNameString(doc.fileName) }) : [];
            const n = $count(files as BCADocumentFile[]) ;
            if (n === 0) {
                toast(defaultWarningToast("Aucun document sélectionné.")) ;
                return ;
            }
            if (($count(session?.documents) + n) > config.session.maxNbDoc) { 
                toast(defaultWarningToast(`Vous avez sélectionné trop de documents. Pour rappel, un dossier de signature ne peut pas en contenir plus de ${config.session.maxNbDoc}.`)) ;
                return ;
            }

            setModalText('Upload en cours...') ;
            onOpen() ;
            const [newDocuments, messages] = await _uploadDocuments(files as BCADocumentFile[], acceptsOfficeDocuments, normalizedFilenames, uploadedDocumentsSize) ;
            messages.forEach(m => toast(toastInfo(m))) ;
            if (newDocuments.length > 0) {
                const newSession = {
                    ...session!,
                    documents: $count(session!.documents) > 0 ? [...session!.documents!, ...newDocuments] : newDocuments
                };
                setSession(newSession) ;
                let uploadedNewDocumentsSize: uint32 = 0 as uint32;
                for (let document of newDocuments) {
                    uploadedNewDocumentsSize = (uploadedNewDocumentsSize + _realDocSize(document.size! as uint32)) as uint32 ;
                }
                setUploadedDocumentsSize((uploadedDocumentsSize + uploadedNewDocumentsSize) as uint32) ;
                _handleViewDocument(newDocuments[0]) ;
            }
            else { toast(defaultErrorToast("Erreur à l'upload des documents choisis.")) ; }
            onClose() ;
        }
        else { toast(defaultErrorToast("Impossible d'uploader de nouveaux documents : session courante introuvable.")) ; }
    };

    const _handleViewDocument = (visibleDocument:NewDocumentDto) => {
        // FIXME: s.signatureDate is allways null or undefined here
        const askedSignatures = $map(session?.signatures, s => $ok(s.signatureDate) ? null : s) ; 
        setDocument(visibleDocument) ;
        setPdfSignatures(askedSignatures) ;
        setRightPaneContent(RightPanelType.Document);
        setCanAddConnectedUser(false) ;
        setPersonRole(undefined) ;
        setDrive(undefined) ;
        setSignature(undefined) ;
    }

    const handleViewDocument = (documentID: UUID) => {
        if ($count(session?.documents) > 0) {
            const visibleDocument = session?.documents?.find(d => d.apid === documentID || d.localUUID === documentID ? d : null ) ;
            if ($ok(visibleDocument)) { _handleViewDocument(visibleDocument!) ; }
            else { toast(defaultErrorToast("Document à visualiser non trouvé.")) ; }
        }
        else { toast(defaultErrorToast("Pas de document à visualiser.")) ; }
    };

    // ==================== right pane documents management ========================================================================================
    const handleRemoveDocument = () => {
        if ($count(session?.documents) > 0 && $ok(document)) {
            setSession({
                ...session!,
                documents: session!.documents?.filter((d) => d !== document),
            });
            setDocument(undefined);
            setRightPaneContent(undefined) ;    
        }
        else { toast(defaultErrorToast("Il n'y a pas de document courant à supprimer.")) ; }
    } ;

    const handleSignatureLinesInDocument = (lines:uint8) => {
        lines = $tounsigned(lines) ;
        optlog('try to set new line to', lines) ;
        if ($count(session?.documents) > 0 && $ok(document)) {
            if (lines !== document?.lastPageSignRows) {
                let newDocument:NewDocumentDto|undefined = undefined ;
                let newDocuments = $map(session?.documents, d => {
                    if (d === document) {
                        d = { ...d, lastPageSignRows:lines } ;
                        newDocument = d ;
                    }
                    return d ;
                })
                setSession({...session!, documents:newDocuments}) ;
                setDocument(newDocument) ;
            }
        }
        else { toast(defaultErrorToast("Document courant introuvable. Impossible de changer le nombre de ligne de signatures sur la dernière page.")) ; }
    } ;

    const handleChangeSession = (_:SessionDto, newSession:SessionDto) => {
        setSession(newSession);
    } ;

    const handleToggleInvisibleSwitch = () => {
        // If creator is in "Visible Signature" mode and clicks to "Invisible Signature" mode, and if advanced signatures are defined, so the signer list is reinitialized
        if (signatureMode === SignatureMode.Visible && ($count(session?.signatures?.filter(signature => signature.phase === SignaturePhase.Server)) !== 0)) {
            setConfirmModal({
                title: "Confirmation",
                message: ["Vous avez défini des signataires en mode avancé. Ce mode n'est pas accepté si vous ne souhaitez pas afficher les signatures dans le document.",
                    "Vous avez donc la possibilté soit de les convertir en mode qualifié, soit de les supprimer de la liste des signataires.",
                    "Que souhaitez-vous faire ?"].join(" "),
                confirm: "Convertir",
                cancel: "Annuler",
                actions: [{
                    label: "Supprimer",
                    value: undefined,
                    onSelect: async (_selectedValue: any) => {
                        const qscdSignatures = session!.signatures?.filter(signature => signature.signatureType ===  SignatureType.AnyQSCD) ;
                        const newSession = { ...session!, signatures: qscdSignatures } ;
                        setSession(newSession) ;
                        setSignatureMode(SignatureMode.Invisible) ;
                        setOptionInvisibleSwitchChecked(true) ;
                        setConfirmModal(DefaultAsyncConfirmModalState) ;
                    }
                }],
                onAccept: async () => {
                    optlog("before signatures => ", session?.signatures) ;
                    for (let signature of session!.signatures!) {
                        signature.signatureType = SignatureType.AnyQSCD ;
                        signature.phase = SignaturePhase.QSCDStart ;
                    } ;
                    optlog("after signatures => ", session?.signatures) ;
                    const newSession = { ...session! } ;
                    setSession(newSession) ;
                    setSignatureMode(SignatureMode.Invisible) ;
                    setOptionInvisibleSwitchChecked(true) ;
                    setConfirmModal(DefaultAsyncConfirmModalState) ;
                },
                onClose: async () => {
                    setOptionInvisibleSwitchChecked(false) ;
                    setConfirmModal(DefaultAsyncConfirmModalState) ;
                }
            }) ;
        } else {
            setSignatureMode(signatureMode === SignatureMode.Visible ? SignatureMode.Invisible : SignatureMode.Visible) ;
            setOptionInvisibleSwitchChecked(!optionInvisibleSwitchChecked) ;
        }
    }

    useEffect(() => {
        if (currentSession) {
            const now = TSDate.zulu() ;
            let workedSession = currentSession ;
            if (systemType === SessionSystemType.Template) {
                if (currentSession.systemType !== SessionSystemType.Template) { // Create template from session
                    workedSession = {
                        title: '',
                        status: SessionStatus.Draft,
                        initialCreator: currentSession.initialCreator,
                        creator: currentSession.creator,
                        expeditor: currentSession.expeditor,
                        validity: new TSInterval(now, now.dateByAddingDays(14)),
                        currentPhase: SignaturePhase.None,
                        signatures: currentSession.signatures
                    } ;
                }
            } else {
                if (currentSession.systemType === SessionSystemType.Template) { // Create session from template
                    workedSession = {
                        title: "",
                        status: currentSession.status,
                        initialCreator: currentSession.initialCreator,
                        creator: currentSession.creator,
                        expeditor: currentSession.expeditor,
                        validity: new TSInterval(now, now.dateByAddingDays($timeBetweenDates(currentSession.validity.start, currentSession.validity.end) / TSDay)),
                        currentPhase: currentSession.currentPhase,
                        signatures: currentSession.signatures,
                        templateId: currentSession.apid
                    } ;
                }
            }
            setSession(workedSession) ;
            setSignatureMode(currentSession!.signatures?.first()?.signatureMode || SignatureMode.Visible) ;
            setOptionInvisibleSwitchChecked(currentSession!.signatures?.first()?.signatureMode === SignatureMode.Invisible) ;
            setBlacklist(undefined);
            setDocument(undefined);
            setDrive(undefined) ;
            setPersonRole(undefined) ;
            setRightPaneContent(undefined) ;
            setCanAddConnectedUser(false) ;
            setPdfSignatures([]) ;
        }
    }, [currentSession]) ;

    return trueOrEmptyCard(
        $ok(profileId) && $ok(userProfile),
        <>
            <PageShellWithThreeColumns
                centralContent={
                    okOrEmptyCard(session,
                        systemType === SessionSystemType.Template ? 
                            (<TemplateSessionCreationPanel
                                session={session!}
                                userProfile={userProfile!}
                                optionInvisibleSwitchChecked={optionInvisibleSwitchChecked}
                                onAddSignerClick={handleAddSigner}
                                onRemoveSigner={handleRemoveSigner}
                                onAddSenderClick={handleAddSender}
                                onChangeSession={handleChangeSession}
                                onSaveTemplate={saveSessionAsTemplate}
                                onToggleInvisibleSwitch={handleToggleInvisibleSwitch}
                                setSession={setSession as Dispatch<React.SetStateAction<SessionDto>>}
                            />)
                            : (<SessionCreationPanel
                                    session={session!}
                                    userProfile={userProfile!}
                                    officeDocuments={acceptsOfficeDocuments}
                                    openFailModal={openFailModal}
                                    optionInvisibleSwitchChecked={optionInvisibleSwitchChecked}
                                    onAddSignerClick={handleAddSigner}
                                    onRemoveSigner={handleRemoveSigner}
                                    onAddSenderClick={handleAddSender}
                                    onChangeSession={handleChangeSession}
                                    onPutToSignature={publishSessionForSignature}
                                    onSaveAsDraft={saveSessionAsDraft}
                                    onSelectDrive={handleSelectDrive}
                                    onToggleInvisibleSwitch={handleToggleInvisibleSwitch}
                                    onUploadFiles={handleUploadDocuments}
                                    onViewDocument={handleViewDocument}
                                    setOpenFailModal={setOpenFailModal}
                                    setSession={setSession as Dispatch<React.SetStateAction<SessionDto>>}
                            />),
                        isLoading,
                        isError)
                }
                rightContent={
                    <>
                        {rightPaneContent === RightPanelType.PersonSelection && (
                            <PersonPanel profileId={profileId!} blacklist={blacklist} role={personRole!} onBack={clearRightPane} onSelectPersonItemAs={handleSelectPersonAs} canaddme={canAddConnectedUser} connectedUserProfile={userProfile!}/>
                        )}
                        {rightPaneContent === RightPanelType.Document && (
                            <DocumentPanel document={document!} message={document!.conversionWarning} signatures={pdfSignatures} signatureMode={signatureMode} onBack={clearRightPane} onRemoveDocument={handleRemoveDocument} onChangeSignatureLines={handleSignatureLinesInDocument} />
                        )}
                        {rightPaneContent === RightPanelType.Drive && (
                            <DriveExplorerPanel drive={drive!} onBack={clearRightPane} onSelectFile={handleSelectFileFromDrive} />
                        )}
                    </>
                }
                isOpen={rightPaneContent !== undefined}
            />
            <AsyncConfirmModal title={confirmModal.title} message={confirmModal.message} size={confirmModal.size} onClose={confirmModal.onClose} onAccept={confirmModal.onAccept} actions={confirmModal.actions} confirm={confirmModal.confirm} cancel={confirmModal.cancel} />
            <WaitingModal isOpen={isOpen} onClose={onClose} message={modalText} />
        </>,
        profileLoading,
        profileError || !$ok(profileId) || !$ok(userProfile),
        "Impossible de récupérer le profil de l'utilisateur",
        "Connexion invalide",
        "Chargement du profil de l'utilisateur"
    ) ;

};


async function _uploadDocuments(files:BCADocumentFile[], acceptsOfficeDocuments:Nullable<boolean>, filenames: string[], uploadedDocumentsSize: uint32):Promise<[NewDocumentDto[], ToastInfo[]]> {
    const ret:NewDocumentDto[] = [] ;
    const messages:ToastInfo[] = [] ;
    const n = files.length ;

    for (let i = 0 ; i < n ; i++) {
        const doc = files[i];
        let warning:string|undefined = undefined ;
        let size = await doc.getSize() ;
        if (size > 0 && $length(doc?.name)) {
            if (size > config.session.maxDocSize) { 
                messages.push({ type: 'warning', text: `Le document ${doc.fileName} dépasse les ${config.session.maxDocSize/(1024*1024)}Mo. Il ne peut pas être sélectionné.` }) ;
                continue ;
            }

            const filteredDoc = filenames.filter(name => (doc.type === BCADocumentFileType.PDF ? name.toLowerCase() : $withoutext(name.toLowerCase())) === fileNameString(doc.fileName).toLowerCase()) ;
            if (filteredDoc.length > 0) {
                messages.push({ type: 'warning', text: `Un document portant un nom semblable à '${doc.fileName}' a déjà été uploadé` }) ;
                continue ;
            }

            let filename = "" ;
            let urlData:Nullable<string> = null ;
            if (doc.type === BCADocumentFileType.PDF) {
                let buf:Nullable<TSDataLike> = await doc.toDataLike() ;
                if (!$length(buf)) { 
                    messages.push({ type:'warning', text:`Erreur à la vérification du document PDF ${doc.fileName}`}) ; 
                }
                else {
                    const existingSignatures = await extractSignaturesFromPdf(buf!) ;
                    buf = null ;
                    if ($count(existingSignatures) > 0) {
                        messages.push({ type:'warning', text:`Le document PDF '${doc.fileName}' a déjà été signé et ne peut pas être utilisé.`}) ;
                    }
                    else {
                        urlData = await doc.toUrlData() ;
                        filename = $newext(doc.fileName, 'pdf');
                        if (!$length(urlData)) { messages.push({ type:'warning', text:`Erreur au chargement du document PDF ${doc.fileName}`}) ; }        
                    }    
                }
            }
            else if (!!acceptsOfficeDocuments) {
                const base64String = await doc.toBase64() ;
                if ($length(base64String)) {
                    const conversion = await g1call('convert to pdf', c => c.convertToPDF({ source:base64String!, fileName:doc.fileName })) ;
                    if ($ok(conversion)) {
                        urlData = conversion!.urlData ; 
                        size = conversion!.fileSize ;
                        filename = `${doc.fileName}.pdf`;
                        messages.push({ type:'success', text:`Le document ${doc.name} ${doc.fileName} a été converti en PDF.`})
                        warning = `Ce document PDF a été converti depuis le document ${doc.name} ${doc.fileName}` ;
                    }
                    else { messages.push({ type:'warning', text:`Erreur à la conversion du document ${doc.name} ${doc.fileName}`}) ; }    
                }
                else { messages.push({ type:'warning', text:`Erreur à la lecture du document ${doc.name} ${doc.fileName}`}) ; }
            }
            else { messages.push({ type:'warning', text:`Le ${i===0?"1er":""+(i+1)+"e"} document n'est pas du bon type.`}) ; }

            if ($length(urlData) > 0) {
                if (uploadedDocumentsSize + _realDocSize(size as uint32) > config.session.maxSessionSize) { // If we exceed the maximum size, we exit the loop
                    messages.push({ type: 'warning', text: `Le document ${filename} ne sera pas pris en compte (sa taille faisant dépasser la taille maximale du dossier).` }) ;
                    continue ;
                }

                ret.push({
                    fileName: filename,
                    fileType:BCADocumentFileType.PDF,
                    mimeType:"application/pdf",
                    size:size as uint32,
                    pagesCount: 0 as uint32,
                    localUUID: $uuid(),
                    localSource:urlData!,
                    lastPageSignRows:3 as uint8,
                    conversionWarning:warning
                }) ;
            }
        }
        else { messages.push({ type:'warning', text:`Le ${i===0?"1er":""+(i+1)+"e"} document n'est pas uploadable.`}) ; }
    }
    
    // if (ret.length > 0 && ret.length < n) {
    //     messages.push({ type:'warning', text:`${n - ret.length} documents sélectionnés n'ont pas pu être uploadés.`})
    // }

    return [ret, messages] ;
}

function _calculateCanAddMe(connectedUserID:Nullable<UUID>, session:Nullable<SessionDto>, role: NeededPersonRole): boolean {
    const ret = $ok(connectedUserID) && $ok(session) && 
                (role === NeededPersonRole.Sender ? 
                    connectedUserID! !== session!.expeditor.apid :
                    $value(session?.signatures, []).findIndex(sign => sign.signer.apid === connectedUserID) === -1
                ) ;
    optlog('_calculateCanAddMe >>>', ret) ;
    return ret ;
}

function _signaturesWithNewSigner(connectedUserID:Nullable<UUID>, session:SessionDto, personItem:PersonItem, phase:SignaturePhase, mode: SignatureMode):SignatureDto[]|string {
    optlog('session want to add signer', $inspect(personItem), session?.signatures?.length)
    if (!$length(connectedUserID)) { return "Connexion au serveur perdue" ; }
    if (!$ok(session)) { return "Session perdue" ; }

    const signatures = $ok(session.signatures) ? [...session.signatures!] : [] ;
    const personID = personItem.person.apid ;

    if ($ok(signatures.find(sign => sign.signer.apid === personID))) { return "Signataire déjà dans votre liste" ; } // the signer is already in our list

    const newSignature:SignatureDto = {
        signatureStatus: SignatureStatus.ToBeOpened,
        signatureType: (phase === SignaturePhase.Server) ? SignatureType.Connective : SignatureType.AnyQSCD,
        signer: personItem.person,
        phase: phase,
        signatureMode: mode,
        rank: 1 as uint32
    } ;

    // if (personItem.isConnectedUser) {
    if (personItem.person.apid === session.expeditor.apid) {
        newSignature.rank = (signatures.length === 0 ? 0 : 2) as uint32 ; 
        // if you add yourself first, 
        // we consider you want the connected user is maint to stay at the first position. 
        // If not, it stays at the end
        signatures.push(newSignature) ;
    }
    else if (signatures.length) {
        const index = signatures.findIndex((sign) => sign.signer.apid === session.expeditor.apid) ; // connectedUserID) ;
        signatures.push(newSignature) ;
        if (index > 0) {
            // we add the expeditor user at the end or in the middle of the list
            const expeditorUserSignature = {...signatures[index], rank:2 as uint32 }
            signatures.splice(index, 1) ;
            signatures.push(expeditorUserSignature) ;
        }
    }
    else { signatures.push(newSignature) ; }
    optlog('signatures', $inspect(signatures)) ;
    return signatures ;
}

function _reloadSignatures(session: SessionDto, expeditor: PersonItem, firstPosition: boolean): SignatureDto[] {
    const signatures = $ok(session.signatures) ? [...session.signatures!] : [] ;
    const index = signatures.findIndex(signature => signature.signer.apid === expeditor.person.apid) ;
    if (index !== -1) {
        const expeditorUserSignature = {...signatures[index], rank: (firstPosition ? 0 : 2) as uint32 } ;
        signatures.splice(index, 1) ;
        return firstPosition ? [expeditorUserSignature, ...signatures] : [...signatures, expeditorUserSignature] ;
    }

    return signatures ;
}

function _changeRankOfOldExpeditor(session: SessionDto) {
    const indexOfOldExpeditor = session.signatures?.findIndex(signature => signature.signer.apid === session!.expeditor.apid) ;
    if ($ok(indexOfOldExpeditor) && (indexOfOldExpeditor !== -1)) {
        session!.signatures![indexOfOldExpeditor as number].rank = 1 as uint32 ;
    }
}

function _realDocSize(length: uint32): uint32 {
    return ((length+63)*41/30) as uint32 ;
}